<template>
  <div class="shortapp__container container">
    <div class="form-container">
    
    
    
    
      <div class="step first box-input" id="step1" v-show="step==1">
        <div class="headline" data-name="Purpose">
          CONGRATULATIONS ON YOUR NEW HOME!
        </div>
        <div style="margin-bottom:50xp;">
          <img src="assets/images/pza.png" style="max-width:280px;" alt="stop">
        </div>
        <div>
          <p>
            LET US TAKE SOME WORRY OFF YOUR HANDS AND GET DINNER DELIVERED HOT & FRESH TO YOUR NEW FRONT DOOR. JUST COMPLETE THIS FORM AND RETURN TO US AT 24 HOURS PRIOR TO YOUR DESIRED DELIVERY DATE.
          </p>
          <p>
            YOU MAY ALSO CALL US AT (561) 625-2660 TO LET US KNOW WHAT YOU'D LIKE.
          </p>
        </div>
        <div>
          <button name="input" type="button" class="sub-but fwd" @click="goNext(2)">Continue</button>
        </div>
      </div>

      <div class="class text-input" id="step2" v-show="step==2">
        <div class="headline">
          CONGRATULATIONS ON YOUR NEW HOME!
        </div>
        <div>
          <img src="assets/images/Prize-Icon-Pizza.png" class="basic-img pizza-icon" alt="pizza icon">

          <img src="assets/images/Diet-Coke-2-liter.png" class="basic-img" alt="diet soda icon">
        </div>

        <div>
          <p>First Pizza</p>
          <div class="form-group">
          <select name="first_pizza_top_1" aria-lable="First Pizza Topping" v-model="pizza.oneToppingOne">
                <option value="" disabled selected="selected">First Topping</option>
                <option value="Extra Cheese">Extra Cheese</option>
                <option value="Pepperoni">Pepperoni</option>
                <option value="Sausage">Sausage</option>
                <option value="Ham">Ham</option>
                <option value="Bacon">Bacon</option>
                <option value="Mushrooms">Mushrooms</option>
                <option value="Black Olives">Black Olives</option>
                <option value="Pineapple">Pineapple</option>
                <option value="Onion">Onion</option>
          </select>
          </div>
          <div class="form-group">
          <select name="first_pizza_top_2" aria-lable="Second Pizza Topping" v-model="pizza.oneToppingTwo">
                <option value="" disabled selected="selected">Second Topping</option>
                <option value="Extra Cheese">Extra Cheese</option>
                <option value="Pepperoni">Pepperoni</option>
                <option value="Sausage">Sausage</option>
                <option value="Ham">Ham</option>
                <option value="Bacon">Bacon</option>
                <option value="Mushrooms">Mushrooms</option>
                <option value="Black Olives">Black Olives</option>
                <option value="Pineapple">Pineapple</option>
                <option value="Onion">Onion</option>
          </select>
          </div>
        </div>
        <div>
          <p>Second Pizza</p>
          <div class="form-group">
          <select name="second_pizza_top_1" aria-lable="Fisrt Pizza Topping" v-model="pizza.twoToppingOne">
                <option value="" disabled selected="selected">First Topping</option>
                <option value="Extra Cheese">Extra Cheese</option>
                <option value="Pepperoni">Pepperoni</option>
                <option value="Sausage">Sausage</option>
                <option value="Ham">Ham</option>
                <option value="Bacon">Bacon</option>
                <option value="Mushrooms">Mushrooms</option>
                <option value="Black Olives">Black Olives</option>
                <option value="Pineapple">Pineapple</option>
                <option value="Onion">Onion</option>
          </select>
          </div>
          <div class="form-group">
          <select name="second_pizza_top_2" aria-lable="Second Pizza Topping" v-model="pizza.twoToppingTwo">
                <option value="" disabled selected="selected">Second Topping</option>
                <option value="Extra Cheese">Extra Cheese</option>
                <option value="Pepperoni">Pepperoni</option>
                <option value="Sausage">Sausage</option>
                <option value="Ham">Ham</option>
                <option value="Bacon">Bacon</option>
                <option value="Mushrooms">Mushrooms</option>
                <option value="Black Olives">Black Olives</option>
                <option value="Pineapple">Pineapple</option>
                <option value="Onion">Onion</option>
          </select>
          </div>
        </div>
        <div>
          <p>Soda</p>
          <div class="form-group">
          <select name="Soda" aria-lable="Soda Choice" v-model="pizza.soda">
                <option value="" disabled selected="selected">Select Soda</option>
                <option value="Coke">Coke</option>
                <option value="Diet Coke">Diet Coke</option>
                <option value="Sprite">Sprite</option>
          </select>
          </div>
        </div>
         <div>
          <button name="input" type="button" class="sub-but fwd" @click="goNext(3)">Continue</button>
        </div>
        
      </div>

      <div class="step" id="step3" v-show="step==3">
        <div class="headline">
          DELIVERY INFORMATION
        </div>
        <div class="form-horizontal inline-form-horizontal co-borrower-form">
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_date" type="date" class="textfield col-sm-8" aria-lable="Delivery Date" placeholder="Delivery Date" aria-label="Delivery Date" v-model="profile.date">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_time" type="time" class="textfield col-sm-8" aria-lable="Delivery Time" placeholder="Delivery Time" aria-label="Delivery Time" v-model="profile.time">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <select name="profile_am_pm" class="textfield col-sm-8" id="am_pm" aria-lable="Am Pm Choice" v-model="profile.amPm">
               <option value="" disabled selected="selected">AM/PM</option>
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select>
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_fullname" type="text" class="textfield col-sm-8"  placeholder="Full Name" aria-label="Full Name" v-model="profile.fullName">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_address" type="text" class="textfield col-sm-8" placeholder="Address" aria-label="Address" v-model="profile.address">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_city" type="text" class="textfield col-sm-8" placeholder="City" aria-label="City" v-model="profile.city">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_state" type="text" class="textfield col-sm-8" placeholder="State" aria-label="State" v-model="profile.state">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_zip" type="text" class="textfeild col-sm-8" placeholder="Zip Code" aria-label="Zip Code" v-model="profile.zipCode">
          </div>
          <div class="form-group">
            <div class="col-sm-3"></div>
            <input name="profile_phone" type="tel" class="textfeild col-sm-8" placeholder="Phone Number" aria-label="Phone Number" v-model="profile.phone">
          </div>
        </div>
        <button name="input" type="button" class="sub-but fwd" @click="goNext(4)">Continue</button>

      </div>

    
      
      <div class="step" id="step4" v-show="step==4">
        <div class="headline">
          HOW DID WE DO?
        </div>
        <p>
          It's a top priority for us to offer you the best experience as possible during the process of you purchasing your new home. Please share your feedback with us through any of the below websites.
        </p>

        <div>
                <a href="https://www.facebook.com/FamilyMortgage" target="_blank"><img class="social" src="assets/images/facebook-logo.png" alt="facebook social"></a>
                <a href="https://www.google.com/search?rlz=1C1CHBF_enUS759US759&amp;ei=JBkGWs_tLoyyjwPKoIbgAg&amp;q=family+mortgage+florida+google+reviews&amp;oq=family+mortgage+florida+google+reviews&amp;gs_l=psy-ab.3...5264.6077.0.6171.8.8.0.0.0.0.100.558.6j1.7.0....0...1.1.64.psy-ab..1.0.0....0.A1I2zfiruwg#lrd=0x88ded58bf59be8e5:0x9c83648c6722124,1,,," target="_blank"><img class="social" src="assets/images/google-logo.png" alt="google social"></a>
                <a href="https://www.yelp.com/biz/family-mortgage-inc-jupiter" target="_blank"><img class="social" src="assets/images/yelp_logo.png" alt="yelp social"></a>
            </div>

        <div>
          <button name="input" id="submit-form" type="button" class="sub-but fwd" @click="submit" :disabled="searchDisabled">Submit Order</button>
        </div>
       
        
      </div>

      <div class="step last" id="step5" v-show="step==5">
        <div>
          <h1>Thank you for submitting your information.<br><br>
          We will contact you shortly</h1>
        </div>
      </div>

      <div class="arrow-container" v-show="arrowShow"><a class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x" @click="goBefore"></i></a></div>
      <!-- <div class="arrow-container"><a href="#" class="arrow-btn back" aria-label="back"><i class="fa fa-arrow-circle-left fa-2x"></i></a> <a href="#" class="arrow-btn fwd" aria-label="next"><i class="fwd fa fa-arrow-circle-right fa-2x"></i></a></div> -->
    
    
    
    
    </div>
  </div>
</template>

<script>
const axios = require('axios');
export default {
  name: 'Content',
  data: () => ({
    step: 1,
    searchDisabled: false,
    pizza: {
      oneToppingOne: "", 
      oneToppingTwo: "", 
      twoToppingOne: "", 
      twoToppingTwo: "", 
      soda: "",
    },
    profile: {
      date: "", 
      time: "", 
      amPm: "",
      fullName: "", 
      address: "",
      city: "", 
      state: "", 
      zipCode: "", 
      phone: "",
    },
  }),
  computed: {
    arrowShow() {
      return this.step != 1 && this.step != 5
    }
  },
  methods: {
    goNext(step) {
      // if((step===5 && this.estimatedHomeValue === "") || (step===6 && this.newLoanAmount === "") || (step===7 && (this.zipFinder === null || this.zipFinder == 0)) || (step===8 && (this.location.address === "" || this.location.city === "" || this.location.state === "" || (this.location.zipCode === null || this.location.zipCode == 0))) || (step===9 && (this.profile.firstName === "" || this.profile.lastName === "" || this.profile.email === "" || (this.profile.phone === null || this.profile.phone == 0))) || (step===13 && (this.coProfile.firstName === "" || this.coProfile.lastName === "" || this.coProfile.email === "" || (this.coProfile.phone === null || this.coProfile.phone == 0)))){
      //   alert("Please Complete Required Fields")
      //   return
      // }

      if((step===3 && (this.pizza.oneToppingOne === "" || this.pizza.twoToppingOne === "" || this.pizza.soda === "")) || (step===4 && (this.profile.fullName === "" || this.profile.address === "" || this.profile.city === "" || this.profile.state === "" || this.profile.zipCode === ""))){
        alert('Please Complete All Required Fields')
        return
      }
      this.step = step
    },

    goBefore() {
      this.step--
    },
    submit() {
      // var c = confirm("Are you really gonna submit?")
      var c = 1;
      if(c){
        this.searchDisabled = true;
        const data = {

          profile: this.profile,
          pizza: this.pizza,
         
        }
        console.log(data);
        axios.post(process.env.VUE_APP_API_URL, data).then(res=>{
          if(res.data && res.data.res == "success")
          {
            // alert("Email sent")
            // location.reload()
            this.step = 5
            this.searchDisabled = false;
          }
          else {
            console.log(res)
          }
        })
      }
    }
  }
}
</script>
