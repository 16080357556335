<template>
  <footer class="footer__main container">
    <div class="row">

      <div class="footer__logo col-sm-4">
          <img src="/assets/images/familymortgage_07_1_1.png" alt="">
      </div>

    <div class="footer__legal col-sm-4">
<!-- <p class="footer__legal-paragraph">Family Mortgage Team is the Jupiter, Florida branch of The Mortgage Firm, Inc whose main office is located at 921 Altamonte Springs, FL 32714, Telephone 407-539-3476</p>
 -->
        <p class="footer__legal-paragraph"><b data-uw-styling-context="true">Family Mortgage Team</b></p>
        <p data-uw-styling-context="true">4600 Military Trail Suite 201</p>
        <p data-uw-styling-context="true">Jupiter, FL 33458</p>
        <p><a href="http://www.familymortgage.com" data-uw-styling-context="true">www.familymortgage.com</a></p>
    </div>

      <div class="col-sm-4">
          <p><b data-uw-styling-context="true">Facebook:</b> <a href="https://www.facebook.com/FamilyMortgage" target="_blank" data-uw-styling-context="true">@FamilyMortgage</a></p>
<p><b data-uw-styling-context="true">Instagram:</b> <a href="https://www.instagram.com/familymortgageteam/" target="_blank" data-uw-styling-context="true">@FamilyMortgageteam</a></p>
 
          <br>
          <h4><i data-uw-styling-context="true">Compliments of Family Mortgage</i></h4>
      </div>
  </div>
  </footer>
</template>

<script>
export default {
  name: 'ComponentFooter'
}
</script>

